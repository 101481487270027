export const FormItems = (store) => {
  return [
    {
      type: 'img',
      label: '店铺LOGO',
      name: 'icon',
      spare: 'shopName',
    },
    {
      type: 'text',
      label: '店铺名称',
      name: 'shopName',
    },
    {
      type: 'input',
      label: '店铺别名',
      name: 'shortName',
      maxLength: 20,
    },
    {
      type: 'text',
      label: '卖家账号',
      name: 'sellerNick',
    },
    {
      type: 'text',
      label: '创建时间',
      name: 'createTime',
    },
    {
      type: 'text',
      label: '授权到期时间',
      name: 'expireTime',
      tip: (info) => {
        return info.expire ? '已过期' : '';
      },
    },
    {
      type: 'input',
      label: '发件人',
      name: 'senderName',
      maxLength: 30,
    },
    {
      type: 'input',
      label: '发件电话',
      name: 'senderTel',
      validator: (rule, value, callback) => store.phoneValidator(rule, value, callback),
    },
    {
      type: 'group',
      label: '发件地址',
      name: 'senderLocation',
      group: [
        {
          type: 'select',
          name: 'senderProvince',
          options: store.provinceList,
          onChange: (e) => store.locationChange('province', e),
          placeholder: '省',
        },
        {
          type: 'select',
          name: 'senderCity',
          onChange: (e) => store.locationChange('city', e),
          placeholder: '市',
        },
        {
          type: 'select',
          name: 'senderDistrict',
          placeholder: '区',
        },
      ],
    },
    {
      type: 'inputTextArea',
      label: ' ',
      name: 'senderAddress',
      colon: false,
      placeholder: '详细地址',
      maxLength: 100,
    },
  ];
};
