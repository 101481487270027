import { FormOutlined } from '@ant-design/icons';
import { Input, Spin, Switch, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import Sticky from 'react-stickynode';
import { PUBLIC_IMAGE_URL } from '../../utils';
import { EditShop } from './components/editShop';
import { DisplayPlatform } from './constant';
import styles from './index.less';
import { ShopAuthorizationStore } from './store';

const store = new ShopAuthorizationStore();

@observer
export default class ShopAuthorization extends React.Component {
  render() {
    return (
      <div className={styles.shopAuthorization}>
        <div className={styles.shopAuthorizationContent}>
          <div className={styles.title}>
            店铺授权管理
          </div>
          <Spin spinning={store.loading}>
            <div className={styles.authorizateContainer}>
              <AddShopAuthorization/>
              <AuthorizationShop/>
            </div>
          </Spin>
          <EditShop store={store.editShopStore}/>
        </div>
      </div>
    );
  }
}

// 新增授权
@observer
class AddShopAuthorization extends React.Component {
  render() {
    return (
      <div className={styles.addShopAuthorization}>
        <div className={styles.subTitle}>
          新增授权店铺
        </div>
        <div className={styles.plateformList}>
          {
            store.plateForm.map((item) => (
              <div
                className={styles.singlePlateform}
                key={nanoid()}
              >
                <img
                  className={styles.singlePlateformIcon}
                  src={item.icon}
                />
                <p className={styles.singlePlateformName}>
                  {item.name}
                </p>
                <a onClick={() => store.goAuthorizate(item.code)}>
                  去授权
                </a>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

// 授权店铺
@observer
class AuthorizationShop extends React.Component {
  render() {
    return (
      <div className={styles.authorizationShop}>
        <Sticky
          innerClass={styles.stickyBar}
          innerZ={2}
        >
          <div className={styles.authorizationShopTitle}>
            <div className={styles.subTitle}>
              已授权店铺
            </div>
            <Input.Search
              allowClear
              className={styles.searchShopInput}
              onSearch={(e) => store.searchShop(e)}
              placeholder="输入店铺名称搜索"
            />
          </div>
          <Tabs
            activeKey={store.activeKey}
            className={styles.plateformTabs}
            items={store.tabItems}
            onChange={(e) => store.changeTabs(e)}
          />
        </Sticky>
        <div className={styles.shopListContainer}>
          <div className={styles.shopList}>
            {store.currentAuthorizationShop.map((item) => (
              <EachShopCard
                item={item}
                key={nanoid()}
                store={store}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

// 每一张店铺卡片
const EachShopCard: React.FC<{ item; store: typeof store; }> = observer((props) => {
  const {
    item,
    store,
  } = props;
  return (
    <div
      className={styles.singleShop}
      key={nanoid()}
    >
      {/* 店铺信息基础信息 */}
      <div className={styles.shopInfo}>
        {/* 店铺图片、名称、卖家 */}
        <div className={styles.infoText}>
          {
            item.icon ? (
              <img
                alt=""
                className={styles.shopImg}
                src={item.icon}
              />
            ) : (
              <div className={styles.shopSign}>
                {item.shopName[0]}
              </div>
            )
          }
          <div>
            <p className={styles.shopName}>
              <span
                className={styles.nameContainer}
                title={item.shopName}
              >
                {item.shopName}
              </span>
              <FormOutlined
                className={styles.editShopIcon}
                onClick={() => store.editShopStore.changeVisible(true, item.shopId)}
              />
            </p>
            <p
              className={styles.sellerNick}
              title={item.sellerNick}
            >
              卖家：
              {item.sellerNick}
            </p>
          </div>
        </div>
        {/* 店铺对应平台icon */}
        <img
          alt=""
          className={styles.plateformIcon}
          src={DisplayPlatform.platformIcon[item.platformType]}
        />
        {
          Boolean(item.expire) && (
            <img
              className={styles.expiredImg}
              src={`${PUBLIC_IMAGE_URL}expired.png`}
            />
          )
        }
      </div>
      {/* 店铺到期时间、启用 */}
      <div className={styles.authorizationStatus}>
        {/* 到期时间 */}
        <div>
          <p className={styles.expireTimeTitle}>
            授权到期时间
          </p>
          <p className={styles.expireTime}>
            {item.expireTime}
          </p>
        </div>
        <Switch
          checked={Boolean(item.enable)}
          checkedChildren="启用"
          onChange={(e) => store.changeShopStatus(item.shopId, Number(e))}
          unCheckedChildren="禁用"
        />
      </div>
    </div>
  );
});
