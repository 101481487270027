import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { EditShopStore } from './components/editShop/store';
import { DisplayPlatform } from './constant';
import type { PlateformProps } from './interface';
import type { DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';

export class ShopAuthorizationStore {
  constructor() {
    this.init();
  }

  @observable public loading = false; // 页面加载

  @observable public plateForm = []; // 展示平台

  @observable public tabItems = [];// 展示平台

  @observable public currentAuthorizationShop = []; // 当前展示的授权店铺

  @observable public editShopStore: EditShopStore;

  @observable public activeKey = ''; // 当前tab

  @observable public shopName = ''; // 当前搜索店铺

  // 初始
  @action
  public init = () => {
    this.getPlateForm();
    this.getAuthorizationShop();
    this.editShopStore = new EditShopStore(this);
  };

  // 获取所有平台
  @action
  public getPlateForm = async() => {
    this.loading = true;
    try {
      const req = await request<BaseData<DictData>>({
        url: '/api/baseinfo/rest/dict/findDictsMapByType/platform_type',
        method: 'get',
      });
      const newData = objToDict(req.data).map((item) => {
        return {
          code: item.value,
          name: item.label,
        };
      });

      // 筛选出需展示的平台
      this.plateForm = newData?.filter((item) => DisplayPlatform.platformType.includes(item.code))
        .map((item) => ({
          ...item,
          icon: DisplayPlatform.platformIcon[item.code],
        }));
      this.tabItems = this.plateForm.map((item) => {
        return {
          key: item.code,
          label: item.name,
        };
      });
    } finally {
      this.loading = false;
    }
  };

  /**
   * 去授权
   * @param code 平台类型
   */
  @action
  public goAuthorizate = async(code) => {
    this.loading = true;
    try {
      const req = await request<BaseData<string>>({
        url: `/api/baseinfo/rest/shop/authByPlatform/${code}`,
        method: 'get',
      });
      window.open(req.data);
    } finally {
      this.loading = false;
    }
  };

  // 改变tab
  @action
  public changeTabs = (e) => {
    this.activeKey = e;
    this.getAuthorizationShop();
  };

  // 搜索店铺
  @action
  public searchShop = (shopName) => {
    this.shopName = shopName;
    this.getAuthorizationShop();
  };

  // 获取当前授权店铺
  @action
  public getAuthorizationShop = async() => {
    this.loading = true;
    try {
      const req = await request<BaseData<[]>>({
        url: '/api/baseinfo/rest/shop/getAuthShopList',
        method: 'post',
        data: {
          platformType: this.activeKey || null,
          shopName: this.shopName || null,
        },
      });
      this.currentAuthorizationShop = req.data;
    } finally {
      this.loading = false;
    }
  };

  /**
   * 改变店铺状态
   * @param shopId 店铺id
   * @param isEnabled 想要关闭、启用
   */
  @action
  public changeShopStatus = async(shopId, isEnabled) => {
    this.loading = true;
    try {
      const req = await request<BaseData>({
        url: '/api/baseinfo/rest/shop/enableSingle',
        method: 'post',
        data: {
          shopId,
          isEnabled,
        },
      });

      // 当请求成功时，店铺状态改变
      this.currentAuthorizationShop.forEach((item) => {
        if (item.shopId === shopId) {
          item.enable = isEnabled;
        }
      });
    } finally {
      this.loading = false;
    }
  };
}
