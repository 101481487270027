import { PUBLIC_IMAGE_URL } from '../../utils';

export const DisplayPlatform = {
  platformType: [
    '1',
    '17',
    '21',
    '22',
  ],
  platformIcon: {
    '1': `${PUBLIC_IMAGE_URL}taobao.png`,
    '17': `${PUBLIC_IMAGE_URL}pdd.png`,
    '21': `${PUBLIC_IMAGE_URL}douyin.png`,
    '22': `${PUBLIC_IMAGE_URL}kuaishou.png`,
  },
};
