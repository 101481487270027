import { LeftOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Spin } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import styles from './index.less';
import type { EditShopStore } from './store';

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

@observer
export class EditShop extends React.Component<{ store?: EditShopStore; }> {
  render() {
    const { store } = this.props;
    return (
      <Form
        ref={store.formRef}
        {...formLayout}
      >
        <Drawer
          className={styles.shopDrawer}
          closeIcon={<LeftOutlined/>}
          footer={[
            <Button
              key={nanoid()}
              onClick={() => store.changeVisible(false, '')}
            >
              取消
            </Button>,
            <Button
              className={styles.submitBtn}
              key={nanoid()}
              loading={store.submitLoading}
              onClick={() => store.submit()}
              type="primary"
            >
              确认修改
            </Button>,
          ]}
          maskClosable={false}
          onClose={() => store.changeVisible(false, '')}
          open={store.visible}
          title="编辑店铺"
          width={434}
        >
          <Spin spinning={store.loading}>
            {
              store.formItems.map((item) => (
                <Form.Item
                  colon={item.colon ?? true}
                  key={nanoid()}
                  label={item.label}
                  name={item.name}
                  rules={[{ validator: (rule, value, callback) => (item.validator ? item.validator(rule, value, callback) : callback()) }]}
                >
                  {store.formContent(item)}
                </Form.Item>
              ))
            }
          </Spin>
        </Drawer>
      </Form>
    );
  }
}
