import { Col, Form, Input, message, Row, Select } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { FormItems } from './constant';
import styles from './index.less';
import type { ProvinceProps, CityProps, DistrictProps, ShopInfoProps } from './interface';

export class EditShopStore {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public formRef = React.createRef<FormInstance>();

  @observable public loading = false; // 页面loading

  @observable public visible = false; // 抽屉显隐

  @observable public shopId = ''; // 店铺Id

  @observable public shopInfo = {}; // 店铺信息

  @observable public formItems: any = FormItems(this); // 表单项

  @observable public submitLoading = false; // 提交loading

  /**
   * 控制抽屉显隐
   * @param visible 显隐
   * @param shopId 店铺id
   */
  @action
  public changeVisible = (visible, shopId) => {
    this.visible = visible;
    this.shopId = shopId;
    if (visible) {
      this.getSingle();
      this.getProvince();
    } else {
      this.formRef?.current?.resetFields();
    }
  };

  // 获取店铺信息
  @action
  public getSingle = async() => {
    this.loading = true;
    try {
      const req = await request<BaseData<ShopInfoProps>>({
        url: `/api/baseinfo/rest/shop/getSingle/${this.shopId}`,
        method: 'get',
      });

      this.shopInfo = req.data;

      this.formRef?.current?.setFieldsValue(this.shopInfo);

      // 获取省份下对应的市、区
      this.getCity(req.data.senderProvince);
      this.getDistrict(req.data.senderCity);
    } finally {
      this.loading = false;
    }
  };

  // 获取省
  @action
  public getProvince = async() => {
    const req = await request<BaseData<any[]>>({
      url: '/api/infrastructure/region/provinces',
      method: 'get',
    });

    // 更新省下拉数据
    this.showOptions('senderLocation', 'senderProvince', (req?.data || []).map((item) => ({
      label: item.provinceName,
      value: item.id,
    })));
  };

  /**
   * 获取市
   * @param parentId 省级id
   */
  @action
  public getCity = async(parentId) => {
    const req = await request<BaseData<Array<{ id: number;cityName: string; }>>>({
      url: '/api/infrastructure/region/cities',
      method: 'get',
      params: { provinceId: parentId },
    });

    // 更新省下拉数据
    this.showOptions('senderLocation', 'senderCity', (req?.data || []).map((item) => ({
      label: item.cityName,
      value: item.id,
    })));
  };

  /**
   * 获取区
   * @param parentId 市级id
   */
  @action
  public getDistrict = async(parentId) => {
    const req = await request<BaseData<Array<{ id: number;districtName: string; }>>>({
      url: '/api/infrastructure/region/districts',
      method: 'get',
      params: { cityId: parentId },
    });

    // 更新省下拉数据
    this.showOptions('senderLocation', 'senderDistrict', (req?.data || []).map((item) => ({
      label: item.districtName,
      value: item.id,
    })));
  };

  /**
   * 展示下拉数据
   * @param groupName 父级key
   * @param singleName 自己的key
   * @param options 下拉数据
   */
  @action
  public showOptions = (groupName, singleName, options) => {
    this.formItems = this.formItems.map((item) => {
      if (item.name === groupName) {
        return {
          ...item,
          group: item.group.map((item) => {
            if (item.name === singleName) {
              return {
                ...item,
                options,
              };
            }
            return item;
          }),
        };
      }
      return item;
    });
  };

  /**
   * 省、市修改
   * @param type 父级的类型
   * @param e 父级值
   */
  @action
  public locationChange = (type, e) => {
    if (type === 'province') {
      this.getCity(e);
      this.formRef?.current?.setFieldsValue({
        senderCity: undefined,
        senderDistrict: undefined,
      });
    } else {
      this.getDistrict(e);
      this.formRef?.current?.setFieldsValue({ senderDistrict: undefined });
    }
  };

  /**
   * 表单内容
   * @param info 单项信息
   * @returns 输入框、输入组、字符串、图片
   */
  @action
  public formContent = (info) => {
    switch (info.type) {
      case 'input':
        return (
          <Input
            allowClear
            maxLength={info.maxLength}
            placeholder={info.placeholder || '请输入'}
          />
        );
      case 'inputTextArea':
        return (
          <Input.TextArea
            allowClear
            maxLength={info.maxLength}
            placeholder={info.placeholder || '请输入'}
            rows={5}
            showCount
          />
        );
      case 'img':
        return (
          this.shopInfo[info.name] ? (
            <img
              className={styles.shopImg}
              src={this.shopInfo[info.name]}
            />
          ) : (
            <div className={styles.shopSign}>
              {this.formRef?.current?.getFieldValue(info.spare)?.substr(0, 1)}
            </div>
          )
        );
      case 'select':
        return (
          <Select
            allowClear
            mode={info.mode}
            onChange={(e) => info.onChange && info.onChange(e)}
            optionFilterProp="label"
            options={info.options}
            placeholder={info.placeholder}
            showSearch
          />
        );
      case 'group':
        return (
          <Row className={styles.groupRow}>
            {
              info.group.map((item) => (
                <Col
                  key={nanoid()}
                  span={Math.floor((24 - info.group.length + 1) / info.group.length)}
                >
                  <Form.Item
                    name={item.name}
                    style={{ marginBottom: 0 }}
                  >
                    {
                      this.formContent(item)
                    }
                  </Form.Item>
                </Col>
              ))
            }
          </Row>
        );
      default:
        return (
          <div>
            <span>
              {this.shopInfo[info.name]}
            </span>
            {
              info.tip && info.tip(this.shopInfo).length && (
                <span className={styles.tip}>
                  {info.tip(this.shopInfo)}
                </span>
              )
            }
          </div>
        );
    }
  };

  // 电话号码验证
  @action
  public phoneValidator = (rule, value, callback) => {
    const regs = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
    if (value && !regs.test(value)) {
      return Promise.reject('请输入正确格式电话号码');
    }
    return Promise.resolve();
  };

  // 提交
  @action
  public submit = () => {
    const { validateFields } = this.formRef?.current;
    validateFields().then(async(res) => {
      this.submitLoading = true;
      try {
        const req = await request({
          url: '/api/baseinfo/rest/shop/updateSingle',
          method: 'post',
          data: {
            ...res,
            shopId: this.shopId,
            senderProvince: res.senderProvince || null,
            senderCity: res.senderCity || null,
            senderDistrict: res.senderDistrict || null,
          },
        });
        message.success('保存成功');
        this.changeVisible(false, '');
      } finally {
        this.submitLoading = false;
      }
    });
  };
}
